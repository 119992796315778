<template>
  <div class="item_list">
    <van-tabs v-model="tabActive" animated sticky swipeable>
      <van-tab
        v-for="(tab, tabIndex) in tabsItem"
        :key="tab.type"
        :title="tab.name"
        @click="tabChange"
      >
        <van-pull-refresh
          :id="`toplist${tabIndex}`"
          v-model="tab.refreshing"
          class="fixheight"
          @refresh="onRefresh(tabIndex, tab.sort)"
        >
          <van-list
            :ref="'tabScrolls' + tabIndex"
            v-model="tab.loading"
            :finished="tab.finished"
            finished-text="没有更多了"
            :error.sync="tab.error"
            error-text="请求失败，点击重新加载"
            offset="100"
            @load="onListLoad(tabIndex, tab.sort)"
          >
            <item-card-indent
              v-for="(item, index) in tab.items"
              :key="index"
              :title="item.nickname ? item.nickname : undefined"
              :usravatar="item.head_pic ? item.head_pic : undefined"
              :n-of-goods="item.order_goods.length"
              :offline-trade="item.order_id === 0"
              :commission="item.commission"
              :cost="item.order_cost"
              :order-time="item.time"
              :status="item.status"
              :order-sn="item.order_sn"
              :goods-array="item.order_goods"
              class="class_item_card"
            />
          </van-list>
        </van-pull-refresh>
      </van-tab>
    </van-tabs>
    <transition name="to-right">
      <van-icon
        v-if="tabsItem[tabActive].showArrow"
        name="arrow-up"
        class="backTop"
        @click.native="backTop(tabActive)"
      />
    </transition>
  </div>
</template>

<script>
import { List, Search, Tab, PullRefresh, Tabs } from 'vant'
import ItemCardIndent from '../../components/item-card-indent'
import { orderList } from '@/api/indent'
import asyncwait from '@/mixins/asyncwait'
import handleNetError from '@/mixins/checklogin'
export default {
  name: 'Indent',
  components: {
    [List.name]: List,
    [Tab.name]: Tab,
    [Tabs.name]: Tabs,
    [Search.name]: Search,
    [PullRefresh.name]: PullRefresh,
    [ItemCardIndent.name]: ItemCardIndent
  },
  mixins: [asyncwait, handleNetError],
  data() {
    return {
      transitionName: 'van-slide-right',
      tabActive: 0,
      sort: this.sortVal,
      tabsItem: [
        {
          name: '全部',
          sort: {},
          showArrow: false,
          loading: false,
          finished: false,
          error: false,
          pageIndex: 1,
          refreshing: false,
          items: []
        },
        {
          name: '未付款',
          sort: { status: 0 },
          showArrow: false,
          loading: false,
          finished: false,
          error: false,
          refreshing: false,
          pageIndex: 1,
          items: []
        },
        {
          name: '已付款',
          sort: { status: 1 },
          showArrow: false,
          loading: false,
          finished: false,
          error: false,
          refreshing: false,
          pageIndex: 1,
          items: []
        },
        {
          name: '已完成',
          sort: { status: 3 },
          showArrow: false,
          loading: false,
          finished: false,
          error: false,
          refreshing: false,
          pageIndex: 1,
          items: []
        }
      ],
      filterItemShow: false,
      scrollTop0: 0
    }
  },
  computed: {
    sortVal() {
      const { tabActive: i } = this
      return this.tabsItem[i].sort
    }
  },
  activated() {
    console.log('indent activated')
    console.log(this.scrollTop0)
    let elem = document.getElementById('toplist0')
    elem.scrollTop = this.scrollTop0
  },
  deactivated() {
    console.log('deactivated')
    let elem = document.getElementById('toplist0')
    this.scrollTop0 = elem.scrollTop
  },
  created() {},
  methods: {
    toggleFilterModal(status) {
      this.filterItemShow = status
    },
    backTop(i) {
      console.log('backTop')
      this.tabsItem[i].showArrow = false
      let elem = document.getElementById(`toplist${i}`)
      elem.scrollTop = elem.scrollTop - elem.scrollTop / 7
      if (elem.scrollTop > 1) {
        setTimeout(() => {
          this.$nextTick(this.backTop(i))
        }, 7)
      } else {
        elem.scrollTop = 0
      }
    },
    tabChange() {
      console.log('tabChange')
    },
    async onRefresh(tabIndex, param) {
      console.log(`onRefresh${tabIndex}`)
      // 清空列表数据
      this.tabsItem[tabIndex].items = []
      this.tabsItem[tabIndex].finished = false
      this.tabsItem[tabIndex].refreshing = false

      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      this.tabsItem[tabIndex].loading = true
      this.tabsItem[tabIndex].pageIndex = 1
      await this.onListLoad(tabIndex, param)
    },
    async onListLoad(tabIndex, param) {
      console.log(`callload${tabIndex}`)
      await this.asyncWait(300)
      try {
        const res = await orderList({
          pageIndex: this.tabsItem[tabIndex].pageIndex,
          pageSize: 5,
          ...param
        })
        if (res.status !== 1) throw res
        this.tabsItem[tabIndex].pageIndex++
        this.tabsItem[tabIndex].items = [
          ...this.tabsItem[tabIndex].items,
          ...res.data
        ]
        this.tabsItem[tabIndex].loading = false
        if (res.data.length < 5) {
          this.tabsItem[tabIndex].finished = true
          // let elem = document.getElementById(`toplist${tabIndex}`)
          // console.log(elem)
          // if (elem) {
          //   if (elem.scrollTop > 200) this.tabsItem[tabIndex].showArrow = true
          // }
        }
        console.log(res)
        console.log('teamBuildLogList cp')
      } catch (err) {
        this.handleNetError(err)
        this.tabsItem[tabIndex].loading = false
        this.tabsItem[tabIndex].error = true
        console.log(err)
      }
    }
  }
}
</script>

<style lang="less" scoped>
.fixheight {
  height: calc(100vh - 44px - 50px);
  overflow-x: hidden;
  overflow-y: scroll;
}
.backTop {
  position: absolute;
  right: 20px;
  bottom: 20px;
  z-index: 10;
  padding: 5px;
  font-size: 24px;
  background-color: #eeeeee;
  border: 1px solid #aaaaaa;
  border-radius: 5px;
}
.to-right-leave {
  transform: translateX(0);
}

.to-right-leave-to {
  transform: translateX(100%);
}

.to-right-enter {
  transform: translateX(-100%);
}

.to-right-enter-to {
  transform: translateX(0);
}
</style>
