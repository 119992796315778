<template>
  <div class="item_card_indent_wrap" @click="OnClick">
    <van-cell :title="title" class="item_title">
      <template #icon>
        <van-icon
          v-if="offlineTrade"
          class="item_title_avatar"
          name="shop-o"
          color="#30AE64"
        />
        <van-image
          v-else
          class="item_title_avatar"
          round
          :src="tavatar"
          @error="imgerror"
        />
      </template>
      <template #default>
        <span class="item_title_value">{{ statusS }}</span>
      </template>
    </van-cell>
    <item-card-goods
      v-for="(item, index) in goodsArray"
      :key="index"
      :goods="item"
      :num="item.goods_num"
      :price="item.final_price"
      :hidestore="true"
    />
    <div class="item_desc_sub">
      <span class="item_desc_sub_id">订单号：{{ orderSn }}</span>
      <span class="item_desc_sub_time">下单时间：{{ orderTime }}</span>
    </div>
    <div class="item_card_divider"></div>
    <div class="item_desc_sub_info">
      <span class="item_desc_sub_input">共{{ nOfGoods }}件商品</span>
      <span class="item_desc_sub_output">实付款：¥{{ cost }}</span>
      <span class="item_desc_sub_store">获得佣金：¥{{ commission }}</span>
    </div>
  </div>
</template>

<script>
import defaultperson from '../../assets/avatar_default.png'
import { Checkbox, Stepper, Icon, Cell, Image } from 'vant'
import ItemCardGoods from '../item-card-goods'
export default {
  name: 'ItemCardIndent',
  components: {
    [ItemCardGoods.name]: ItemCardGoods,
    [Checkbox.name]: Checkbox,
    [Stepper.name]: Stepper,
    [Image.name]: Image,
    [Cell.name]: Cell,
    [Icon.name]: Icon
  },
  props: {
    title: {
      type: String,
      default: '线下交易'
    },
    status: {
      type: Number,
      default: 0
    },
    usravatar: {
      type: String,
      default: defaultperson
    },
    goodsArray: {
      type: Array,
      default: () => []
    },
    orderSn: {
      type: String,
      default: '订单号'
    },
    orderTime: {
      type: String,
      default: '下单时间'
    },
    cost: {
      type: String,
      default: '0.00'
    },
    commission: {
      type: String,
      default: '0.00'
    },
    offlineTrade: {
      type: Boolean,
      default: false
    },
    nOfGoods: {
      type: Number,
      default: 0
    },
    num: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      defaultperson,
      tavatar: this.usravatar,
      checked: false,
      value: 1
    }
  },
  computed: {
    statusS() {
      const statusString = [
        '未付款',
        '已付款',
        '等待分成',
        '已分成',
        '已取消',
        '未知'
      ]
      let tmp = this.status > 5 ? 5 : this.status
      return statusString[tmp]
    }
  },
  methods: {
    OnClick() {
      this.$emit('click')
    },
    imgerror() {
      console.log('imgerror')
      this.tavatar = defaultperson
    }
  }
}
</script>

<style lang="less" scoped>
@import '@/style/global.less';
.item_card_indent_wrap {
  display: flex;
  flex-direction: column;
  margin: 14px;
  overflow: hidden;
  background-color: #ffffff;
  border-radius: 6px;
}
.item_desc_sub {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 100%;
  height: 58px;
  padding: 0 14px;
  font-size: @font-size-small;
  color: #676767;
  background-color: #ffffff;
  &_id {
    color: #999999;
  }
  &_time {
    color: #999999;
  }
}
.item_desc_sub_info {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  height: 34px;
  font-size: @font-size-normal;
  color: #333333;
  background-color: #ffffff;
}
.item_title {
  height: 45px;
  font-family: Source Han Sans CN;
  font-size: 14px;
  font-weight: 400;
  line-height: 30px;
}
.item_title_avatar {
  width: 30px;
  height: 30px;
  margin-right: 8px;
  font-size: 28px;
  border-radius: 15px;
}
.item_title_value {
  font-family: Source Han Sans CN;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  color: #ff7043;
}
</style>
